import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent } from "vue"

const _hoisted_1 = { class: "container-list" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "mb-8" }
const _hoisted_4 = { class: "mb-4" }
const _hoisted_5 = {
  key: 0,
  class: "p-8 step-box"
}
const _hoisted_6 = {
  key: 1,
  class: "step-box"
}
const _hoisted_7 = { class: "p-4 flex bg-gray" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 0 }

import { computed, defineAsyncComponent, ref, watch } from 'vue'
import { usePDRepository } from '@/entityes/invoice/personal_data/pd.repository'
import useWindowWidth from '@/use/useWindowWidth'
import usePolitic from '@/use/usePolitic'
import useGtagManager from '@/use/useGtagManager'
import { usePDStore } from '@/entityes/invoice/personal_data/pd.store'
import { IPersonalData } from '@/entityes/invoice/personal_data/pd.type'
import { ElNotification, ElMessageBox } from 'element-plus'
import { UploadFilled } from '@element-plus/icons-vue'
import { useInvoiceStore } from '@/entityes/invoice/invoice.store'
import { useInvoiceRepository } from '@/entityes/invoice/invoice.repository'
import { useDeclarationStore } from '@/entityes/invoice/declaration/declaration.store'
import { useDeclarationRepository } from '@/entityes/invoice/declaration/declaration.repository'
import { useAsyncInvoiceReload } from '@/entityes/invoice/useAsyncInvoiceReload'
import ClearLoader from '@/07-Shared/ui/ClearLoader.vue'
import { useStepper } from '@/entityes/invoice/useStepper'
interface IProps {
  id: number | string
}

export default /*@__PURE__*/_defineComponent({
  __name: 'PersonalData',
  props: {
    id: {}
  },
  setup(__props: any) {

const PersonalDataCard = defineAsyncComponent(() => import('./PersonalDataCard'))
const BankAccount = defineAsyncComponent(() => import('@/components/Invoice/Declaration/BankAccount'))

const props = __props
const pdStore = usePDStore()
const { getPersonalData, getCurrentPersonalDataCard, createPersonalData, removePersonalDataCard } = usePDRepository()
const { isAdmin } = usePolitic()
const { event } = useGtagManager()
const hasCurrentCard = ref(false)
const { screenLessValue } = useWindowWidth()
const invoiceStore = useInvoiceStore()
const declarationStore = useDeclarationStore()
const noPersonalData = ref(
  (invoiceStore.invoice?.personal_data &&
    Object.keys(invoiceStore.invoice?.personal_data).length === 0 &&
    !invoiceStore.invoice.status.actions_for_goods?.Declaration?.includes('Personal_data')) ||
    false
)
const { getInvoice } = useInvoiceRepository()
const { initAsync } = useAsyncInvoiceReload()
const stepper = useStepper()
const emptyPersonalData = {
  id: 0,
  title: 'Новая форма',
  name: '',
  last_name: '',
  patronymic: '',
  tax_inspection_code: '',
  phone: '',
  inn: '',
  birth_date: '',
  birth_place: '',
  passport: '',
  passport_issued: '',
  passport_issued_date: '',
  oktmo: '',
}
const currentTitle = computed(() => pdStore?.currentPD?.title)
const selectCard = ref(currentTitle.value || '')
const loading = ref<boolean>(false)

// получение списка карточек с сервера
function getAllPersonalData() {
  return new Promise((resolve, reject) => {
    getPersonalData(props.id).then(() => {
      getCurrentPersonalDataCard(props.id)
        .then((res) => {
          hasCurrentCard.value = true
          selectCard.value = currentTitle.value
          resolve(res)
        })
        .catch((err) => {
          hasCurrentCard.value = false
          selectCard.value = ''
          reject(err)
        })
    })
  })
}
// Выбрать карточку из списка
function tookPersonalDataItem(id: number | string) {
  pdStore.currentPD = pdStore.pds?.personal_datas.find((i) => i.id === id)
}
// открепление карточки от инвойса
function deleteCurrentCard() {
  removePersonalDataCard(props.id, pdStore?.currentPD?.id as string | number).then(() => {
    getAllPersonalData()
  })
}
// сообщение об удалении ПД из инвойса
function showMsgToDeleteCard() {
  if (hasCurrentCard.value && noPersonalData.value) {
    const msg =
      'Вы уже сформировали декларацию с персональными данными. Вы хотите сформировать эту декларацию без персональных данных?'
    ElMessageBox.confirm(msg, 'Warning', {
      confirmButtonText: 'да',
      cancelButtonText: 'нет',
      type: 'warning',
    })
      .then(() => {
        deleteCurrentCard()
        ElNotification({
          title: `Успешно`,
          message: noPersonalData.value
            ? 'декларация сформирована без персональных данных'
            : 'персональные данные привязаны к декларации',
          type: 'success',
        })
        noPersonalData.value = false
      })
      .catch(() => {})
  }
}
// создание карточки
function sendPersonalData(val: IPersonalData) {
  const body = noPersonalData.value ? emptyPersonalData : val

  createPersonalData(props.id, body as IPersonalData)
    .then(() => {
      ElNotification({
        title: noPersonalData.value ? 'Декларация сформирована' : `Персональные данные`,
        message: noPersonalData.value ? 'без персональных данных' : 'успешно привязаны к декларации',
        type: 'success',
      })
      getAllPersonalData().finally(() => {
        getInvoice(props.id).then((res) => {
          initAsync(res).then((res) => {
            stepper.selectStep(res)
          })
        })
      })
    })
    .catch((err) => {
      ElNotification({
        title: `Ошибка`,
        message: `во время привязки персональных данных к декларации. Попробуйте еще раз. ${err}`,
        type: 'error',
      })
    })
}

const hasDeclaration = computed(() => !!(invoiceStore.invoice && invoiceStore.invoice.goods.Declaration))

watch(noPersonalData, showMsgToDeleteCard)
// если admin отправлять событие внутреннего траффика
if (isAdmin?.value) {
  event('internal_traffic')
}

function init() {
  loading.value = true
  useInvoiceRepository()
    .getInvoice(props.id)
    .then((res) => {
      initAsync(res).then(() => {
        if (hasDeclaration.value) {
          useDeclarationRepository().getDeclaration(res?.goods.Declaration)
        }
        getAllPersonalData()
          .catch((err) => {
            console.log(err)
          })
          .finally(() => (loading.value = false))
      })
    })
}
init()

return (_ctx: any,_cache: any) => {
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[6] || (_cache[6] = _createElementVNode("h1", { class: "mb-2" }, "Персональные данные", -1)),
    _cache[7] || (_cache[7] = _createElementVNode("p", { class: "mb-4" }, " Заполните персональные данные, чтобы сформировать документы для загрузки в личный кабинет налоговой. ", -1)),
    (loading.value)
      ? (_openBlock(), _createBlock(ClearLoader, { key: 0 }))
      : (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_el_checkbox, {
                class: "mb-4",
                modelValue: noPersonalData.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((noPersonalData).value = $event)),
                label: "Сформировать документы без персональных данных"
              }, null, 8, ["modelValue"]),
              (noPersonalData.value)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[3] || (_cache[3] = [
                    _createElementVNode("p", { class: "mb-4 font-bold" }, "но тогда вам нужно будет:", -1),
                    _createElementVNode("ul", { style: {"list-style-position":"inside","list-style-type":"disc"} }, [
                      _createElementVNode("li", null, "скачать программу декларация на сайте ФНС"),
                      _createElementVNode("li", null, "загрузить в нее проект декларации без личных данных (файл .dcx)"),
                      _createElementVNode("li", null, "внести персональные данные"),
                      _createElementVNode("li", null, "выгрузить готовую декларацию")
                    ], -1)
                  ])))
                : _createCommentVNode("", true)
            ]),
            (noPersonalData.value)
              ? (_openBlock(), _createBlock(_component_el_button, {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (sendPersonalData(emptyPersonalData))),
                  type: "success",
                  class: "button-main",
                  icon: _unref(UploadFilled)
                }, {
                  default: _withCtx(() => _cache[4] || (_cache[4] = [
                    _createTextVNode(" Сохранить ")
                  ])),
                  _: 1
                }, 8, ["icon"]))
              : _createCommentVNode("", true),
            (!noPersonalData.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    (_unref(pdStore).pds?.personal_datas.length > 0)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                          _cache[5] || (_cache[5] = _createElementVNode("h4", { class: "mb-4" }, "Выбор сохраненных персональных данных", -1)),
                          _createVNode(_component_el_select, {
                            modelValue: selectCard.value,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((selectCard).value = $event)),
                            onChange: tookPersonalDataItem,
                            placeholder: "Выберите форму"
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(pdStore).pds?.personal_datas, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.id,
                                  value: item.id,
                                  label: item.title
                                }, null, 8, ["value", "label"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue"])
                        ]))
                      : _createCommentVNode("", true)
                  ]),
                  (_openBlock(), _createBlock(_resolveDynamicComponent(_unref(PersonalDataCard)), {
                    item: _unref(pdStore).currentPD,
                    onSendDataEvent: sendPersonalData,
                    key: _unref(pdStore).currentPD?.id,
                    "is-loading": _unref(getPersonalData).indicator.value === true
                  }, null, 40, ["item", "is-loading"]))
                ]))
              : _createCommentVNode("", true)
          ]),
          (_unref(declarationStore)?.declaration && _unref(isAdmin) && hasDeclaration.value)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_unref(BankAccount), {
                  "good-id": _unref(declarationStore)?.declaration?.id
                }, null, 8, ["good-id"])
              ]))
            : _createCommentVNode("", true)
        ]))
  ]))
}
}

})